//
//
//
//
//
//
//
//

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    linkLabel: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: '/'
    }
  }
};
