import { defineComponent, computed, onMounted } from '@vue/composition-api';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import SkipLinks from '@/shared/components/SkipLinks.vue';
import { useOrganizationBankQuery } from '@/generated-types/graphql.types';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    name: 'SpaceSuccessPage',
    components: {
        SkipLinks
    },
    setup() {
        const { result: organizationBank } = useOrganizationBankQuery();
        const information = computed(() => organizationBank?.value?.fetch_iban_details);
        onMounted(() => {
            localStorage.removeItem('createdVenueId');
            globalThis.$store.commit('$_vendor/CLEAN_SPACE_DATA');
        });
        return {
            information,
            ROUTE_LIST_NAMES,
            useGetLocalizedPath
        };
    }
});
